.myOrder{
    width: 100%;
    display: block;
    .title{
       width: 100%;
       font-size: 0;
       vertical-align: top;
       
       li{
           width: 96px;
           padding: 0 9px;
           display: inline-block;
           *display: inline;
           *zoom: 1;
           font-size:14px;
           line-height: 20px;
           color: #4A4A4A;
           text-align: center;   
           padding-bottom: 8px;
           cursor: pointer;  
           &.active,&:hover{
               color: #DC2728;
               border-bottom: solid 2px #DC2728;
           }
           a{
               color: inherit;
           }
       } 
    }   
    .myOrder-title{
        width: 100%;
        height: 30px;
        background: #F6F6F6;
        border: solid 1px #F6F6F6;
        table-layout: fixed;
        tr{
            border: none;
            th{
                border-right: solid 1px #E1E1E1;
                font-size:14px;
                background: #F6F6F6;
                color: #828282;
                font-weight: normal;
                &:nth-last-child(1){
                    border-right:0;
                }
            }
        }
    }
    .big{
        width: 35%;
    }
    .mean{
        width: 21%;
    }
    #order-list{
        min-height: 500px;
    }
    .myOrder-list{
        width: 100%;
        display: block;
        
        table {
            border: none;
            font-size: 16px;
//          table-layout: fixed;
            tr{
                width: 100%;
                th{
                    height: 26px;
                    background: #F6F6F6;
                    color: #828282;
                    font-size: 12px;
                    font-weight: normal;
                    border:solid 1px #e1e1e1;
                    .code{
                        float: left;
                        padding-left: 20px;
                        font-size: 14px;
                    }
                    .date{
                        float: right;
                        padding-right: 11px;
                        font-size: 14px;
                    }
                }
                td{
                    height: 114px;
                    border:solid 1px #e1e1e1;
                    padding: 10px 0;
                    &.Price{
                        line-height: 16px;
                    }
                    strong,.txt{
                        display: block;
                        padding-left: 20px;
                        line-height: 20px;
                    }
                    strong{
                        font-size: 16px;
                        color: #3B3A40;
                        line-height: 20px;
                        padding-bottom: 6px;
                    }
                    
                    span{
                        color: #828282;
                        font-size: 12px;
                        line-height: 14px;
                        margin: 2px 0;
                        &.c_red{
                            font-size: 16px;
                        }
                    }
                    .btnOrder,.btn{
                        width: 100px;
                        height: 26px;
                        background: #F6F6F6;
                        text-align: center;
                        line-height: 26px;
                        border-radius: 3px;
                        display: block;
                        margin:6px 0;
                        &.payment{
                            background: #DC2728;
                            color: #FFF;
                        }
                    }

                    .bF6F6F6{
                        background: #F6F6F6;
                        color: #828282;
                    }
                    .bDC2728{
                        background: #DC2728;
                        color: #fff;
                    }
                }
            }
        }
    }
}

//下单支付
.payment-pay{
    padding: 20px;
    background: #FFF;
    border-radius: 8px;
    position: relative;
   .payment-Price{
       font-size: 14px;
       color: #828282;
       .red{
           color: #DC2728;
       }
       .Price{
           font-weight: bold;
           font-size: 26px;
       }
       #seeOrderDetails{
           position: absolute;
           right: 20px;
           top: 20px;
           color: #dc2728;
           font-size: 14px;

       }
   }
   // 支付页面订单明细
    #order-details{
        padding: 20px 0;
        display: none;
        dl{
            width: 100%;
            font-size: 0;
            vertical-align: top;
            dd,dt{
                font-size: 14px;
                text-align: left;
                line-height: 200%;
                color: #666;
                display: inline-block;
            }
            dt{
                width: 120px;
                color: #999;
            }
        }
    }
   .tips{
       font-size: 12px;
       color: #828282;
       padding-top: 8px;
       padding-bottom: 20px;
       border-bottom: 1px solid #e2e3e7;
   }
   .payment-name,.payment-check{
       //display: inline-block;
       vertical-align: middle;
   }
   .payment-name{
       color: #828282;
       font-size: 14px;
       line-height: 200%;
       position: relative;
       top: -2px;
    }
   .payment-method{
       .payment-check{
           font-size: 0;
           vertical-align: top;
           div.clear{
               border:none;
           }
           >div{
               float: left;
               width: 48%;
               border-radius: 8px;
               border:1px solid #e2e3e7;
               text-align: center;
               background: #f9f9f9;
               box-sizing: border-box;
               color: #fff;
               overflow: hidden;
               cursor: pointer;
               //-webkit-transition: 0.5s all;
               //-moz-transition: 0.5s all;
               //-ms-transition: 0.5s all;
               //-o-transition: 0.5s all;
               //transition: 0.5s all;
               &.active{
                   border:2px solid #dc2728;
                   background: #FFF;
               }
               .top{
                   padding: 18px 0;
               }
               .pay{
                   font-size: 16px;
                   color: #333;
                   display: none;
                   p{
                       padding-bottom: 30px;
                   }
               }
           }
            .zfb{
                margin-right: 20px;
            }


   }
   
   
}


.paymentBtn{
        padding-left:74px;
        button{
            width: 160px;
            height: 40px;
            background: #DC2728;
            color: #fff;
            font-size: 14px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }   
   }
}
//微信支付
.payment_wx{
    font-size: 14px;
    line-height: 150%;
    color: #828282;
    display: none;
}
//查看订单进度
.order-progress{
    width: 100%;
    //height: 300px;
    overflow: auto;
    overflow-x: hidden;
    dl{
        width: 100%;
        //text-align: left;
        font-size: 0;
        vertical-align: top;
        border-bottom: 1px solid #e1e1e1;
        &.noBorder{
            border-bottom: none;
        }
        dt,dd{
            font-size: 14px;
            padding: 10px 10px;
            display: inline-block;
            *display:inline;
            *zoom: 1;
        }
        dt{
            width: 180px;
        }
        dd{
            width: 340px;
        }
    }
}

.evpi_title{
    padding: 20px 0;
    .container{
        font-size: 14px;
        .c_note;
    }
}
.evpi_wrap{
    .container{
        background: #FFF;
        -webkit-border-radius:8px;
        -moz-border-radius:8px;
        border-radius:8px;
        padding: 30px 0;
        dl{
            font-size: 0;
            vertical-align: top;
            margin-top: 20px;
            dt,dd{
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
            }
            dt{
                width: 160px;
                line-height: 200%;
                text-align: right;
            }
            dd{
                padding-left: 20px;
                .c_note;
                .img{
                    width: 220px;
                    height: 130px;
                    border: 1px solid #f6f6f6;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .w150{
                    width: 150px;
                }
                .del{
                  .c_note;
                    font-size: 26px;
                }
                .w160{
                    width: 159px;
                }
                .w199{
                    width: 199px;
                }
                .w240{
                    width: 240px;
                }
                .w669{
                    width: 656px;
                }
                .w829{
                    width: 809px;
                }
                .w828{
                    width: 828px;
                }
                .jdw{
                    line-height: 40px;
                    margin-left: 8px;
                }
                .search{
                    width: 297px;
                    height: 38px;
                    line-height: 38px;
                    border:1px solid #DC2728;
                    border-radius: 8px;
                    font-size: 0;
                    vertical-align: top;
                    margin-right: 30px;
                    display: inline-block;
                    input{
                        width: 217px;
                        height: 18px;
                        line-height: 18px;
                        border:none;
                        padding:10px 5px;
                        margin-left: 10px;
                        font-size: 14px;
                        vertical-align: top;
                        display: inline-block;
                    }
                    .search_btn{
                        width: 60px;
                        height: 38px;
                        line-height: 38px;
                        font-size: 14px;
                        text-align: center;
                        color: #FFF;
                        background: #DC2728;
                        border-top-right-radius: 6px;
                        border-bottom-right-radius: 6px;
                        display: inline-block;
                    }
                }

            }

        }
    }
}
//选择区域
.select_area{
    width: 300px;
    position: absolute;
    left:50px;
    top: 100px;
    z-index: 99;
    >ul{
        width: 148px;
        height: 207px;
        float: left;
        background: #F6F6F6;
        border:1px solid #E1E1E1;
        &.city{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        &.area{
            border-left: none;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        li{
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            text-align: center;
            &.active,&:hover{
                background: #e1e1e1;
                cursor: pointer;
            }
        }
    }
}
//选择经营范围
.business-scope{
    width: 610px;
    height: 430px;
    //position: absolute;
    //left: 500px;
    //top:200px;
    //z-index: 9999;
    border:1px solid #eee;
    background: #FFF;
    border-radius: 8px;
    .title{
        width: 100%;
        height: 84px;
        line-height: 74px;
        font-size: 18px;
        .c_note;
        text-align: center;
        position: relative;
        p.c_note{
            width: 100%;
            text-align: center;
            position: absolute;
            left:0;
            bottom: 10px;
            font-size: 12px;
        }
        .close{
            font-size: 26px;
            position: absolute;
            right: 20px;
            top:18px;
            .c_red;
        }
    }
    .tabs-nav{
        width: 100%;
        height: 30px;
        background: #F6F6F6;
        a{
            width: 16.6%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            float: left;
            .c_title;
            &:hover,&.active{
                .c_red;
                border-bottom: 2px solid #dc2728;
            }
        }
    }
    .tabs-content{
        padding: 20px;
        li{
            min-width: 100px;
            padding: 5px 0;
            float: left;
        }
    }

}