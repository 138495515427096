@import "../common/mixin.less";
.release_status{
  width: 100%;
  padding-top: 21px;
  .container{
    height: 46px;
    border-bottom: 2px solid #D8D8D8;
    .subNav,.btn{
      display: inline-block;
    }
    .subNav{
      padding-top: 10px;
      font-size: 14px;
      a{
        height: 36px;
        line-height: 36px;
        padding:0 24px;
        position: relative;
        .c_gray;
        .c_gray;
        display: inline-block;
        &.active{
          .c_red;
          border-bottom: 2px solid #dc2728;
        }
        .line{
          width: 1px ;
          height: 12px;
          background: #E1E1E1;
          position: absolute;
          right: 0;
          top:12px;
        }
      }
    }
    .btn{
      display: inline-block;
      position: absolute;
      right:0;
      bottom:6px;
    }


  }
}



//活动列表


.activity-list{
  min-height: 900px;
  dl.item{
    padding:16px;
    position: relative;
    font-size: 0;
    vertical-align: top;
    font-size: 14px;
    background: #FFF;
    margin-top: 16px;
    dd,dt{
      display: inline-block;
      vertical-align: top;
    }
    dt{
      width: 200px;
      height: 145px;
      background: #e0e0e0;
      margin-right: 16px;
      img{
        width: 100%;
        height: 100%;
      }
      .icon{
          font-size: 12px;
          white-space: nowrap;
          position: absolute;
          left: 0;
          top: 25px;
          margin: 0px 0 0 -10px;
          text-align: center;
          line-height: 33px;
          text-indent: -4px;
      }
    }
    dd{
      width: 734px;
      .name{
        font-size: 18px;
        height: 50px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 500px;
      }
      .other{
        margin-bottom: -7px;
        .c_note;
        span{
          .c_title;
        }
      }
      p{
        line-height: 200%;
      }
      .btn{
        position: absolute;
        right: 19px;
        bottom: 16px;
        a{
          margin-left: 6px;
        }
      }
      .handle{
        position: absolute;
        right: 19px;
        top:16px;
        a{
          padding: 0 10px;
          position: relative;
          .c_gray;
          &:nth-last-child(1){
            padding-right: 0;
          }
          &.disabled{
            color: #D7D7D7 !important;
            .iconfont{
              color: #D7D7D7;
            }
          }
          .line{
            width: 1px ;
            height: 12px;
            background: #E1E1E1;
            position: absolute;
            right: 0;
            top:2px;
          }
          &.edit{
            .iconfont{
              font-size: 18px;
              position: relative;
              top:1px;
            }
          }
        }
      }
    }
  }
}


.spirit-icon1 {
    height: 33px;
    width: 79px;
    color: #666;
    background: url("../img/icon/icon.png") -0px -0px no-repeat;
    background-size: 300px 300px;
    display: inline-block;
    overflow: hidden;
}
.spirit-icon2 {
  height: 33px;
  width: 60px;
  color: #ff9f00;
  background: url("../img/icon/icon.png") -80px -0px no-repeat;
  background-size: 300px 300px;
  display: inline-block;
  overflow: hidden;
}
.spirit-icon3 {
  height: 33px;
  width: 60px;
  color: #FFF;
  background: url("../img/icon/icon.png") -141px -0px no-repeat;
  background-size: 300px 300px;
  display: inline-block;
  overflow: hidden;
}
.spirit-icon4 {
  height: 33px;
  width: 60px;
  color: #f00015;
  background: url("../img/icon/icon.png") -203px -0px no-repeat;
  background-size: 300px 300px;
  display: inline-block;
  overflow: hidden;
}
.spirit-icon5 {
  height: 33px;
  width: 60px;
  color: #FFF;
  background: url("../img/icon/icon.png") 0px -34px no-repeat;
  background-size: 300px 300px;
  display: inline-block;
  overflow: hidden;
}
//#gopng_icon_1_outer {
//height: 24px;
//width: 49px;
//overflow: hidden;
//}
//#gopng_icon_1 {
//background: url("../img/icon/icon.png") -48px -0px no-repeat;
//width: 100%;
//height: 100%;
//_background: none;
//_padding-left: 48px;
//_margin-left: -48px;
//_padding-top: 0px;
//_margin-top: -0px;
//_filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod=crop,src="http://gopng/static/images/sprite.png");
//}
//#gopng_icon_3_outer {
//height: 24px;
//width: 49px;
//overflow: hidden;
//}
//#gopng_icon_3 {
//background: url("../img/icon/icon.png") -97px -0px no-repeat;
//width: 100%;
//height: 100%;
//_background: none;
//_padding-left: 97px;
//_margin-left: -97px;
//_padding-top: 0px;
//_margin-top: -0px;
//_filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod=crop,src="http://gopng/static/images/sprite.png");
//}