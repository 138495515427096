//项目列表
.project-list {
  min-height: 900px;
  dl.item {
    padding: 16px 16px 16px 108px;
    position: relative;
    font-size: 0;
    vertical-align: top;
    background: #FFF;
    margin-top: 16px;
    color: #828282;
    dd, dt {
      display: inline-block;
      vertical-align: top;
    }
    dt {
      width: 76px;
      height: 76px;
      background: #e0e0e0;
      margin-right: 16px;
      position: absolute;
      top: 16px;
      left: 16px;
      img {
        width: 100%;
        height: 100%;
      }
      .icon {
        font-size: 12px;
        white-space: nowrap;
        position: absolute;
        left: 0;
        top: 25px;
        margin: 0px 0 0 -10px;
        text-align: center;
        line-height: 33px;
        text-indent: -4px;
      }
    }
    dd {
      width: 100%;
      font-size: 14px;
      .name {
        font-size: 18px;
        line-height: 150%;
        overflow: hidden;
        padding-right: 150px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #3B3A40;
      }
      .other {
        margin-bottom: -7px;
        .c_note;
        span {
          .c_title;
        }
      }
      p {
        line-height: 200%;
        &.txt {

        }
      }
      .table {
        width: 100%;
        margin-top: 16px;
        background: #F6F6F6;
        td {
          border-right: 1px solid #FFF;
          text-align: center;
          padding: 14px 0;
          color: #828282;
          strong {
            color: #3B3A40;
          }
        }
      }
      .handle {
        position: absolute;
        right: 19px;
        top: 16px;
        a {
          padding: 0 10px;
          position: relative;
          .c_gray;
          &:nth-last-child(1) {
            padding-right: 0;
          }
          &.disabled {
            color: #D7D7D7 !important;
            .iconfont {
              color: #D7D7D7;
            }
          }
          .line {
            width: 1px;
            height: 12px;
            background: #E1E1E1;
            position: absolute;
            right: 0;
            top: 2px;
          }
          &.edit {
            .iconfont {
              font-size: 18px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }
  }
}

//添加项目开始
.send-project-content {
  position: relative;
  .mark {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .send_title {
    .container {
      -webkit-transition: all 0s;
      -moz-transition: all 0s;
      -ms-transition: all 0s;
      -o-transition: all 0s;
      transition: all 0s;
    }
  }
  &.hiddend {
    .send-project {
      display: none;
    }
    .send_title {
      padding: 0;
      .container {
        background: #FFF;
        border: 1px solid #E1E1E1;
        border-radius: 8px;
        margin-top: 20px;
        height: 60px;
        line-height: 60px;
        text-indent: 20px;
      }
    }
  }
  &.readOnly {
    .mark {
      display: block;
    }
    input, select {
      background: #FFF;
      border: none;
      outline: none;
      box-shadow: none;
      /*将默认的select选择框样式清除*/
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
    }
    .btns-save {
      display: none;
    }
    .del, .btn_b_red, .btn {
      display: none;
    }
    dl {
      margin-top: 0 !important;
      height: 30px;
      line-height: 30px;
    }
    .mt20 {
      margin-top: 0 !important;
    }
    .mt15 {
      margin-top: 10px !important;
    }
  }
}

.send-project {

  .edit-bd {
    &.edit {
      > .bp-list-item {
        display: none;
      }
      .bp-list-item {
        background: #F6F6F6;
      }
      .see {
        display: none;
      }
      .handlers {
        display: none;
      }

      dl, .btns-save {
        display: block;
      }
    }
    dl, .btns-save {
      display: none;
    }
    .bp-list-item {
      background: none;
    }
  }
  .btns-save {
    padding: 0 20px;
    text-align: right;
    button {
      width: 110px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
      color: #FFF;
      background: #DC2728;
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .container {
    background: #FFF;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 30px 0;
    dl {
      font-size: 0;
      vertical-align: top;
      margin-top: 20px;
      padding-left: 180px;
      padding-right: 20px;
      position: relative;
      &.noPadding {
        padding-left: 20px;
      }
      dt, dd {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
      }
      dt {
        width: 160px;
        line-height: 290%;
        text-align: right;
        position: absolute;
        left: 0px;
      }

      dd {
        width: 100%;
        position: relative;
        .c_note;
        &.projectHisList {
          padding-left: 330px;
          padding-right: 30px;
          position: relative;
          .span5 {
            position: absolute;
            left: 0;
            font-size: 0;
            vertical-align: top;
            select{
              margin-right: 6px;
            }
          }
          .span7 {
            width: 100%;
          }
          .del {
            position: absolute;
            right: 0;
            top: 9px;
          }
        }
        .img {
          width: 220px;
          height: 160px;
          border: 1px solid #f6f6f6;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .address {
          position: relative;
          display: inline-block;
          .pos {
            position: absolute;
            right: 8px;
            top: 3px;
            font-size: 24px;
            .c_red;
          }
        }
        input {
          margin-right: 3px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
        .w150 {
          width: 150px;
        }
        .w160 {
          width: 159px;
        }
        .w199 {
          width: 199px;
        }
        .w240 {
          width: 240px;
        }
        .w260 {
          width: 270px;
        }
        .w669 {
          width: 656px;
        }
        .w829 {
          width: 809px;
        }
        .w828 {
          width: 828px;
        }
        .jdw {
          line-height: 40px;
          margin-left: 8px;
        }
        .search {
          width: 297px;
          height: 38px;
          line-height: 38px;
          border: 1px solid #DC2728;
          border-radius: 8px;
          font-size: 0;
          vertical-align: top;
          margin-right: 30px;
          display: inline-block;
          input {
            width: 217px;
            height: 18px;
            line-height: 18px;
            border: none;
            padding: 10px 5px;
            margin-left: 10px;
            font-size: 14px;
            vertical-align: top;
            display: inline-block;
          }
          .search_btn {
            width: 60px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            text-align: center;
            color: #FFF;
            background: #DC2728;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            display: inline-block;
          }
        }

      }

    }
    //嘉宾列表
    .guest_list {
      //width: 100%;
      margin-right: 20px;
      box-sizing: border-box;
      font-size: 14px;
      margin-bottom: 20px;
      border: 1px solid #E1E1E1;
      border-radius: 8px;
      .item {
        border-bottom: 1px solid #e1e1e1;
        margin: 0 10px;
        &:nth-last-child(1) {
          border: none;
        }
      }
      p {
        height: 40px;
        line-height: 40px;
        display: inline-block;
        padding: 5px 10px;
        &.hanlder {
          float: right;
          span {
            margin-left: 10px;
            font-size: 20px;
            position: relative;
            top: 3px;
          }
        }
        .del, .edit {
          cursor: pointer;
        }
      ;
      }
      .img {
        width: auto;
        height: auto;
        border: none;
        img {
          width: 40px;
          height: 40px;
          border-radius: 40px;
        }
      }
      .companyName {
        min-width: 200px;
      }
    }
    //主办方
    .sponsor-list {
      display: inline-block;
      vertical-align: top;
      &._w199 {
        .item {
          width: 199px;
        }
      }
      .item {
        background: #f6f8fa;
        height: 38px;
        line-height: 38px;
        padding: 0 7px;
        border-radius: 8px;
        border: 1px solid #dbdfe5;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 4px;
        vertical-align: top;
        .c_gray;
        .close {
          cursor: pointer;
          margin-left: 9px;
          position: relative;
          top: 1px;
          .c_note;
        }
      }
    }
    .sponsor {
      min-width: 110px;
      width: 127px;
      height: 42px;
      overflow: hidden;
      border-radius: 8px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      text-align: left;
      display: inline-block;
      &.active {
        background: #dc2728;
      }
      .btn_b_red {
        width: 80px;
      }
      input {
        width: 0px;
        height: 0;
        border: none;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
      .confirm {
        display: none;
      }
      &.active {
        width: 309px;
        input {
          width: 224px;
          height: 18px;
          padding: 8px 4px;
          margin-left: 4px;
          border-radius: 8px;
          display: inline-block;
        }
        .confirm {
          display: inline-block;
        }
        .add {
          display: none;
        }
        .btn_b_red {
          color: #FFF !important;
          width: 60px;
        }
      }

    }
  }
}

.send_agree {
  width: 100%;
  text-align: center;
  padding: 40px 0 35px 0;
  font-size: 14px;
}

//选择图片弹窗
.select_img {
  .title {
    width: 100%;
    height: 74px;
    line-height: 74px;
    font-size: 18px;
    .c_note;
    text-align: center;
  }
  .list-item {
    height: 398px;
    overflow: auto;
    .inline-block();
    font-size: 0;
    vertical-align: top;
    li {
      width: 220px;
      margin-right: 4px;
      .inline-block();
      font-size: 14px;
      .c_note;
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
      img {
        margin-bottom: 8px;
        width: 220px;
        height: 160px;
        .opacity(50);
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }
      &.active, &:hover {
        img {
          .opacity(100);
        }
      }
    }
  }
}

.upload-file {
  .img_show {
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px dashed #E1E1E1;
    background: #F6F6F6;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    img {
      height: 60px;
      width: 60px;
      display: none;
    }
    input {
      height: 60px;
      width: 60px;
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      .opacity(0);
    }
  }

}

#projectLogo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.image_list {
  vertical-align: top;
  display: inline-block;
  span.img_i {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin: 0 6px 6px 0;
    display: inline-block;
    position: relative;
    overflow: hidden;
    .del{
      position: absolute;
      right: 0px;
      top:0px;
      line-height: 100%;
      z-index:1;
      cursor: pointer;
      font-size: 20px;
    }
    img {
      width: 100%;
      min-height: 100%;
    }
  }
}

.projectFooter {
  width: 100%;
  height: 150px;
  margin-top: 40px;
  background: #F2F2F2;
  border-top: 1px solid #D7D7D7;
  text-align: center;
}

//bp-list
.bp-list-item {
  padding: 15px 15px;
  font-size: 0;
  vertical-align: top;
  background: #F6F6F6;
  border-radius: 8px;
  margin-bottom: 15px;
  .span9, .span3 {
    font-size: 12px;
    vertical-align: top;
    display: inline-block;
  }
  .span9 {
    width: 70%;
    color: #828282;
    .title {
      font-size: 14px;
      color: #666;
    }
    .span1, .span11 {
      font-size: 0;
      vertical-align: top;
      display: inline-block;
    }
    .span1 {
      width: 20px;
      input[type='radio'] + label {
        top: 0 !important;
      }
    }
    .span11 {
      * {
        font-size: 12px;
      }
    }
  }
  .span3 {
    width: 30%;
    text-align: right;
    color: #666;
    line-height: 120%;
    p {
      line-height: 150%;
      .iconfont {
        position: relative;
        top: 2px;
      }
    }
  }
}

//添加嘉宾
.select_firends {
  width: 500px;
  height: 500px;
  border-radius: 8px;
  padding: 0 30px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  box-sizing: content-box;
  text-align: left;
  .search {
    padding-right: 120px;
    position: relative;
    .l {
      width: 100%;
    }
    .r {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .title {
    width: 100%;
    height: 75px;
    line-height: 75px;
    font-size: 18px;
    text-align: center;
    .c_note;
  }
  .list {
    height: 250px;
    overflow: auto;
  }
  dl {
    width: 100%;
    font-size: 0;
    vertical-align: top;
    text-align: left;
    background: #F6F6F6;
    //border-radius: 8px;
    margin-top: 2px;
    padding: 8px 14px;
    &.active {
      background: #DC2728;
      color: #FFF;
      * {
        color: #FFF !important;
      }
    }
    dd, dt {
      font-size: 14px;
      .c_title;
      display: inline-block;
      vertical-align: top;
    }
    dd {
      color: #666;
      strong {
        color: #3B3A40;
        font-weight: normal;
      }
    }
    dt {
      position: relative;
      margin-right: 10px;
      .img {
        width: 40px;
        height: 40px;
        border: 1px solid #E1E1E1;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
  .btn {
    text-align: center;
    margin-top: 35px;
  }
}

//项目成员里列表
.list-item-member {
  .name {
    font-size: 14px;
    color: #828282;
    margin-bottom: 15px;
    strong {
      font-size: 16px;
      color: #3B3A40;
    }
    .hanlder {
      float: right;
      color: #828282;
      a {
        color: #828282;
        .iconfont {
          margin-right: 6px;
        }
      }
    }
  }
}

//融资历史
.financing_history, .start_financing {
  width: 500px;
  height: 400px;
  border-radius: 8px;
  padding: 0 30px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  box-sizing: content-box;
  text-align: left;
  .title {
    width: 100%;
    height: 75px;
    line-height: 75px;
    font-size: 18px;
    text-align: center;
    .c_note;
  }
  .list {
    height: 250px;
    overflow: auto;
  }
  dl {
    padding-left: 123px;
    font-size: 0;
    vertical-align: top;
    text-align: left;
    margin-bottom: 20px;
    position: relative;
    dd, dt {
      font-size: 14px;
      .c_title;
      display: inline-block;
    }
    dt {
      width: 103px;
      padding-right: 20px;
      text-align: right;
      position: absolute;
      left: 0;
      top: 0;
      line-height: 40px;
    }
    dd {
      position: relative;
      width: 100%;
      &.line_2 {
        padding-right: 20px;
        input, select {
          width: 50%;
          &:nth-child(2) {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
      .img {
        width: 90px;
        height: 90px;
        border: 1px solid #E1E1E1;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }

  .btn {
    text-align: center;
    margin-top: 35px;
  }
}

//融资历史记录展示
.show_financing_history {
  padding-left: 20px;
  padding-right: 30px;
  .item {
    display: table;
    width: 100%;
    height: 40px;
    line-height: 40px;
    position: relative;
    font-size: 16px;
    background: #F6F6F6;
    margin-bottom: 10px;
    .span2, .span3, .span5 {
      display: table-cell;
      padding-left: 10px;
      border-right: 1px solid #FFF;
      line-height: 40px;
    }
    .span2 {
      width: 14%;
    }
    .span3 {
      width: 20%;
    }
    .del {
      position: absolute;
      top: 10px;
      right: -23px;
    }
  }

}

//运行数据展示
.show_operativeData {
  width: 500px;
  background: #F6F6F6;
  padding: 20px;
  border-radius: 8px;
  p {
    font-size: 0;
    vertical-align: top;
    span {
      font-size: 14px;
      color: #666;
      padding: 5px 0;
      vertical-align: top;
      display: inline-block;
      &.l {
        width: 80%;
      }
      &.r {
        width: 20%;
        text-align: right;
      }
    }

  }
}

//添加运营数据
.add_operativeData_pop {
  width: 620px;
  height: 400px;
  border-radius: 8px;
  //padding: 0 30px 0 210px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  box-sizing: content-box;
  text-align: left;
  .title {
    width: 100%;
    height: 75px;
    line-height: 75px;
    font-size: 18px;
    text-align: center;
    .c_note;
  }
  .content {
    height: 325px;
    padding-left: 210px;
    position: relative;
    .span3 {
      width: 180px;
      padding-left: 30px;
      height: 325px;
      overflow: auto;
      position: absolute;
      left: 0px;
      top: 0;
      box-sizing: content-box;
      background: #F6F6F6;
      border-right: 2px solid #FFF;
      font-size: 14px;
      color: #666;
      h3 {
        font-weight: normal;
        font-size: inherit;
        padding-bottom: 8px;
        margin-top: 20px;
      }
      ul {
        li {
          line-height: 23px;
        }
      }
    }
    .span9 {
      width: 100%;
      height: 325px;
      overflow: auto;
      background: #F6F6F6;
      ul {
        padding-left: 20px;
        li {
          font-size: 0;
          vertical-align: top;
          margin-top: 16px;
          .l, .r {
            vertical-align: top;
            font-size: 14px;
            display: inline-block;
          }
          .l {
            width: 130px;
            line-height: 40px;
          }
          .r {
            width: 240px;
          }
        }
      }
    }
  }
}