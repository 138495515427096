.send_title{
  padding: 20px 0;
  .container{
    font-size: 14px;
    .c_note;
  }
}
.send-activity{
  .container{
    background: #FFF;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    border-radius:8px;
    padding: 30px 0;
    dl{
      font-size: 0;
      vertical-align: top;
      margin-top: 20px;
      dt,dd{
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
      }
      dt{
        width: 160px;
        line-height: 200%;
        text-align: right;
      }
      dd{
        padding-left: 20px;
        width: 828px;
        position: relative;
        .c_note;
        .img{
          width: 220px;
          height: 160px;
          border: 1px solid #f6f6f6;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .address{
          position: relative;
          display: inline-block;
          .pos{
            position: absolute;
            right: 8px;
            top:3px;
            font-size: 24px;
            .c_red;
          }
        }
        input{
          margin-right:3px;
          &:nth-last-child(1){
            margin-right: 0;
          }
        }
        .w150{
          width: 150px;
        }
        .w160{
          width: 159px;
        }
        .w199{
          width: 199px;
        }
        .w240{
          width: 240px;
        }
        .w260{
          width: 270px;
        }
        .w669{
          width: 656px;
        }
        .w829{
          width: 809px;
        }
        .w828{
          width: 828px;
        }
        .jdw{
          line-height: 40px;
          margin-left: 8px;
        }
        .search{
          width: 297px;
          height: 38px;
          line-height: 38px;
          border:1px solid #DC2728;
          border-radius: 8px;
          font-size: 0;
          vertical-align: top;
          margin-right: 30px;
          display: inline-block;
          input{
            width: 217px;
            height: 18px;
            line-height: 18px;
            border:none;
            padding:10px 5px;
            margin-left: 10px;
            font-size: 14px;
            vertical-align: top;
            display: inline-block;
          }
          .search_btn{
            width: 60px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            text-align: center;
            color: #FFF;
            background: #DC2728;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            display: inline-block;
          }
        }

      }

    }
    //嘉宾列表
    .guest_list{
      //width: 100%;
      margin-right: 20px;
      box-sizing: border-box;
      font-size: 14px;
      margin-bottom: 20px;
      border:1px solid #E1E1E1;
      border-radius: 8px;
      .item{
        border-bottom: 1px solid #e1e1e1;
        margin:0 10px;
        &:nth-last-child(1){
          border:none;
        }
      }
      p{
        height: 40px;
        line-height: 40px;
        display: inline-block;
        padding: 5px 10px;
        &.hanlder{
          float: right;
          span{
            margin-left: 10px;
            font-size: 20px;
            position: relative;
            top:3px;
          }
        }
        .del,.edit{
          cursor: pointer;
        };
      }
      .img{
        width: auto;
        height: auto;
        border:none;
        img{
          width: 40px;
          height: 40px;
          border-radius:40px;
        }
      }
      .companyName{
        min-width: 200px;
      }
    }
    //主办方
    .sponsor-list{
      display: inline-block;
      vertical-align: top;
      &._w199{
        .item{
          width: 199px;
        }
      }
      .item{
        background: #f6f8fa;
        height: 38px;
        line-height: 38px;
        padding: 0 7px;
        border-radius: 8px;
        border:1px solid #dbdfe5;
        display: inline-block;
        margin-right: 4px;
        margin-bottom: 4px;
        vertical-align: top;
        .c_gray;
        .close{
          cursor: pointer;
          margin-left: 9px;
          position: relative;
          top:1px;
          .c_note;
        }
      }
    }
    .sponsor{
      min-width: 110px;
      width: 127px;
      height: 42px;
      overflow: hidden;
      border-radius: 8px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      text-align: left;
      display: inline-block;
      vertical-align: top;
      &.active{
        background: #dc2728;
      }
      .btn_b_red{
        width: 80px;
      }
      input{
        width: 0px;
        height: 0;
        border:none;
        box-sizing: content-box;
        vertical-align: top;
        margin-top: 4px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
      .confirm{
        display: none;
      }
      &.active{
        width: 309px;
        input{
          width: 224px;
          height: 18px;
          padding: 8px 4px ;
          margin-left: 4px;
          border-radius: 8px;
          display: inline-block;
        }
        .confirm{
          display: inline-block;
        }
        .add{
          display: none;
        }
        .btn_b_red{
          color: #FFF !important;
          width: 60px;
        }
      }

    }
  }
}
.send_agree{
  width: 100%;
  text-align: center;
  padding: 40px 0 35px 0;
  font-size: 14px;
}
//选择图片弹窗
.select_img{
  .title{
    width: 100%;
    height: 74px;
    line-height: 74px;
    font-size: 18px;
    .c_note;
    text-align: center;
  }
  .list-item{
    height: 398px;
    overflow: auto;
    .inline-block();
    font-size: 0;   
    vertical-align: top;
    li{
      width: 220px;
      margin-right: 4px;
      .inline-block();
      font-size: 14px;
      .c_note;
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
      img{
        margin-bottom: 8px;
        width: 220px;
        height: 160px;
        .opacity(50);
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }
      &.active,&:hover{
        img{
          .opacity(100);
        }
      }
    }
  }
}
//选择城市
.select_city{
  width: 542px;
  height: 469px;
  background: #F6F6F6;
  border:1px solid #E1E1E1;
  border-radius: 8px;
  overflow: auto;
  overflow-x: hidden;
  //position: absolute;
  //left:10px;
  //top:20px;
  z-index: 99;
  .tabs_nav{
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 0;
    vertical-align: top;
    border-bottom: 1px solid #E1E1E1;
    a{
      width: 16.6%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      text-align: center;
      .c_red;
      display: inline-block;
      *display: inline;
      *zoom: 1;
    }
  }
  .tabs_content{
    padding: 10px 15px;
    dl{
      width: 100%;
      font-size: 16px;
      dt{
        .c_red;
      }
      dd{
        font-size: 0;
        vertical-align: top;
        a{
          width: 16.6%;
          font-size: 16px;
          padding: 3px 0;
          .c_gray;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          &:hover{
            .c_red;
          }
        }
      }
    }
  }
}

//活动发布成功
.send_activity_success{
  .container{
    padding: 50px 0;
    margin: 20px auto;
    background: #FFF;
    text-align: center;
    .title{
      font-size: 30px;
      .iconfont{
        font-size: 80px;
        line-height: 110%;
        color: #dc2728;
      }
    }
    .desc{
      font-size: 16px;
      width: 740px;
      text-align: left;
      margin: 20px 0;
      display: inline-block;
    }
    .link{
      font-size: 16px;
      margin-top: 12px;
    }
    .qrcode{
      img{
        width: 157px;
        height: 157px;
      }
    }
    .btn{
      margin-top: 30px;
    }
  }
}
//添加嘉宾
.addGuest{
  width: 550px;
  height: 500px;
  border-radius: 8px;
  background: #FFF;
  position: absolute;
  left:0;
  top:0;
  z-index: 99;
  .title{
    width: 100%;
    height: 75px;
    line-height: 75px;
    font-size: 18px;
    text-align: center;
    .c_note;
  }
  dl{
    width: 100%;
    font-size: 0;
    vertical-align: top;
    text-align: left;
    margin-bottom: 20px;
    dd,dt{
      font-size: 14px;
      .c_title;
      display: inline-block;
    }
    dt{
      width: 103px;
      padding-right: 20px;
      text-align: right;
    }
    dd{
      position: relative;
      input{
        width: 340px;
        height: 18px;
        line-height: 18px;
        padding: 16px 10px;
        border-radius: 8px;
        border:1px solid #E1E1E1;
      }
      .upload{
        width: 90px;
        height: 90px;
        background: #F6F6F6;
        border:1px solid #E1E1E1;
        border-radius: 8px;
        color: #D7D7D7;
        font-size: 40px;
        text-align: center;
        line-height: 90px;
        position: absolute;
        left:0;
        top:0;
        &.bgn{
          background: none;
          font-size: 0;
        }
      }
      .img{
        width: 90px;
        height: 90px;
        border:1px solid #E1E1E1;
        border-radius: 8px;
        img{
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
  .btn{
    text-align: center;
    margin-top: 35px;
  }
}


//编辑框
#editor1{
  height: 400px;
}

//活动须知
.activity-notice{
  text-align: left;
  p{
    font-size: 16px;
    padding: 3px 0;
    font-weight: bold;
  }
  ol{
    padding-left: 2em;
    li{
      padding: 3px 0;
      .num{
        width: 16px;
        height: 16px;
        line-height: 16px;
        border-radius: 50%;
        margin:0 10px 0 0;
        text-align: center;
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        border:1px solid #e1e1e1;
      }
    }
  }
}
//活动协议
.activity-protocol{
  text-align: left;
  line-height: 150%;
  height: 354px;
  overflow: auto;
  p{
    font-size: 16px;
    line-height: 150%;
    font-weight: bold;
  }
  ol{
    list-style-type: decimal;
    padding-left: 2em;
    li{
      line-height: 150%;
      padding: 8px 0;
      font-size: 14px;
      list-style-type: decimal;
    }
  }
}
//手机预览
.mobilePreview{
  width: 366px;
  height: 746px;
  background: url("../img/mobile.png");
  .close_preview{
    color: #FFF;
    position: absolute;
    right: 4px;
    top: 0px;
    font-size: 30px;
  }
}
