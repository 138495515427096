.sms-manage{
  .container{
    background: #FFF;
    margin-top: 20px;
    .sms-name{
      padding: 30px 0;
      font-size: 20px;
      .c_title;
      text-align: center;
    }
    .send-sms{
      padding: 0 30px 30px;
      border-bottom: 1px solid #F6F6F6;
      .title{
        margin-bottom: 15px;
        font-size: 16px;
        .c_gray;
      }
      .content{
        font-size: 16px;
        .tabs{
          height: 40px;
          line-height: 40px;
          font-size: 0;
          vertical-align: top;
          background: #F6F6F6;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          border-bottom: 1px solid #FFF;
          a{
            height: 40px;
            line-height: 40px;
            padding: 0 30px;
            font-size: 14px;
            display: inline-block;
            vertical-align: top;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            &.first{
              border-top-left-radius: 8px;
            }
            .c_gray;
            &.active,&:hover{
              background: #dc2728;
              color: #FFF !important;
            }
          }
        }
        .info{
          padding: 20px;
          height: 48px;
          position: relative;
          background: #F6F6F6;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          display: none;
          textarea{
            border:none;
            resize: none;
            width: 100%;
            background: none;
            text-align: left;
            font-size: 16px;
          }
          .prompt{
            position: absolute;
            bottom: 5px;
            right: 20px;
          }
        }
      }
      .prompt{
        font-size: 14px;
        margin-top: 12px;
        .c_note
      }
      .btn{
        margin-top: 20px;
        text-align: right;
      }
    }
  }
}
//  短信历史记录
.sms-history{
  .container{
    background: #FFF;
    padding-bottom: 30px;
    margin-bottom: 20px;
    .title{
      padding: 30px 30px 20px 30px;
      font-size: 16px;
      .c_gray
    }
    .content{
      font-size: 14px;
      margin:  0 30px;
      border:1px solid #E1E1E1;
      border-radius: 8px;
      table{
        width: 100%;
        td,th{
          border-right: 1px solid #E1E1E1;
          border-top: 1px solid #E1E1E1;
          font-weight:normal;
        }
        .bn{
          border-right:none;
          padding: 20px;
        }
        .bt{
          th{
            border-top:none;
          }
        }
      }
    }
  }
}