.signup-manage{
  width: 100%;
  min-height: 900px;
  .container{
    height: 100%;
    background: #FFF;
    margin-top: 20px;

  }
  .signup-name{
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    .c_title;
    border-bottom: 1px solid #F6F6F6;
  }
  .signup-status{
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 20px;
    .c_title;
    border-bottom: 1px solid #F6F6F6;
    font-size: 0;
    vertical-align: top;
    .span6{
      text-align: left;
      height: 80px;
      line-height: 80px;
      &.btn{
        text-align: right;
        a{
          margin-right: 16px;
        }
      }
    }
    .signup_num{
      padding-left: 30px;
      .c_gray;
      i{
        font-style: normal;
        .c_title;
      }
    }
  }
  .charts{
    width: 100%;
    height: 225px;
    border-bottom: 1px solid #F6F6F6;
    font-size: 0;
    vertical-align: top;
    >div{
      width: 30%;
      height: 225px;
      vertical-align: top;
      display: inline-block;
    }
  }
  #charts2{
    margin-right: 6.6%;
  }
  .filter{
    .span6{
      height: 40px;
      vertical-align: top;
      .btn{
        padding: 10px 26px;
        font-size: 14px;
        border:1px solid #DC2728;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;;
        .c_red;
        margin-right: 16px;
        display: inline-block;
        &.disabled{
          color: #D7D7D7 !important;
          border-color: #D7D7D7;
        }
      }
      .search{
        width: 297px;
        height: 38px;
        line-height: 38px;
        border:1px solid #DC2728;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        font-size: 0;
        vertical-align: top;
        text-align: left;
        margin-right: 30px;
        display: inline-block;
        .select{
           width: 60px;
          height: 38px;
          font-size: 14px;
          text-align: center;
          background: #F6F6F6;
          border-right:1px solid #e9e9e9;
          display: inline-block;
          vertical-align: top;
          border-top-left-radius: 8px;
          position: relative;
          .c_gray;
          p{
            line-height: 38px;
          }
          ul{
            position: absolute;
            left:-1px;
            top:40px;
            width: 100%;
            background: #FFF;
            border:1px solid #e9e9e9;
            border-top:0;
            border-bottom: 0;
            display: none;
            li{
              width: 100%;
              height: 40px;
              line-height: 40px;
              text-align: center;
              border-bottom:1px solid #e9e9e9;
              .c_gray;
            }
          }
        }
        input{
          width: 165px;
          height: 18px;
          line-height: 18px;
          border:none;
          padding:10px 5px;
          font-size: 14px;
          vertical-align: top;
          display: inline-block;
        }
        .search_btn{
          width: 60px;
          height: 38px;
          line-height: 38px;
          font-size: 14px;
          position: relative;
          left:1px;
          text-align: center;
          color: #FFF;
          background: #DC2728;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          display: inline-block;
        }
      }
    }
  }
  .signup-list{
    font-size: 14px;
    table{
      width: 100%;
      margin-top: 25px;
      th{
        .c_note;
      }
      td,th{
        text-align: left;
        padding:12px 0;
        border-bottom: 1px solid #E1E1E1;
        font-weight: normal;
        label{
          top:10px;
        }
        &.btn{
          a{
            margin-right: 20px;
          }
        }
      }
    }
  }
}

//签到管理
.registration{
  .title{
    width: 100%;
    height: 74px;
    line-height: 74px;
    font-size: 18px;
    border-bottom: 1px solid #E1E1E1;
    text-align: center;
    position: relative;
    .close{
      font-size: 26px;
      position: absolute;
      right: 20px;
      top:18px;
      .c_red;
    }
  }
  .info{
    font-size: 16px;
    text-align: left;
    padding-left: 35px;
    .c_gray;
    .txt{
      padding: 20px 0;
    }
    .step{
      vertical-align: middle;
      font-size: 0;
      div{
        width: 230px;
        height: 130px;
        border:1px solid #DC2728;
        border-radius: 8px;
        font-size: 14px;
        text-align: center;
        display: inline-table;
        vertical-align: middle;
        margin-right: 10px;
        p{
          display: table-cell;
          vertical-align: middle;
        }
        .iconfont{
          font-size: 36px;
          color: #dc2728;
        }
      }
    }
  }
  .prompt{
    width: 100%;
    height: 1px;
    background: #E1E1E1;
    position: relative;
    text-align: center;
    margin-top: 30px;
    .txt{
      font-size: 14px;
      .c_gray;
      //position: absolute;
      //left: 0;
      //top:-10px;
      //right: 0;
      //margin:auto;
      background: #FFF;
      padding: 0 5px;
      display: inline-block;
      position: relative;
      top: -10px;;
    }
  }
}