.home-container{
  min-height: 500px;
  .g_title{
    font-size: 14px;
    color: #828282;
    margin:20px 0 10px 0;
  }
  .g_content{
    background: #FFF;
    border-radius: 8px;
    .myOrder{
      height: 430px;
      overflow: hidden;
    }
    .g_btn{
      margin-top: 17px;
      text-align: center;
    }

  }
  .g_padding{
    padding: 20px 43px;
  }
  .g_padding2{
    padding: 41px 43px;
  }
  //活动
  .home-activity{
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    .more{
      position: absolute;
      right: 20px;
      top:20px;
      font-size: 14px;
      .c_red;
    }
    canvas{
      width: 100px;
      height: 100px;
      margin-left: -33px;
      margin-top: -48px;
      margin-bottom: -50px;
    }
    .jqm-round-wrap2{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: #F2F2F2;
      vertical-align: top;
      display: inline-block;
      font-size: 14px;
      margin-left: 22px;
      a{
        width: 100px;
        height: 100px;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        color: #828282;
        span{
          display: block;
          font-size: 28px;
        }
      }
      .iconfont{
        color: #DC2728;
      }
    }
    .jqm-round-wrap{
      display: inline-block;
      margin-right: 83px;
      .c_orange2{
        color: #E0441F;
      }
      .c_yellow2{
        color: #FFD100;
      }
    }
  }
}
